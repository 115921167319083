import {finalize, tap} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {TmSocialInfos} from "@app/shared/enums";
import {Template} from "@app/shared/interfaces";
import {inject, Injectable} from '@angular/core';
import {setContext} from "@app/shared/constants";
import {AppStateService} from "@app/shared/services/app-state.service";
import {createFormData, sfFormatDate, sortBy} from "@app/shared/helpers";

@Injectable({
  providedIn: 'root'
})
export class TemplateService {

  private readonly httpClient = inject(HttpClient);
  private readonly appStateService = inject(AppStateService);

  public getTemplates() {
    return this.httpClient.get<Template[]>('api/v1/dashboard/all-documents')
      .pipe(tap((res) => sortBy(res)));
  }

  public addTemplate(template: Template) {
    const user = this.appStateService.user;

    template.settings.title.text = user?.fullName || '';

    template.settings.socialInfo.items.forEach((item) => {
      switch (item.type) {
        case TmSocialInfos.EMAIL: {
          item.text = user?.email || '';
          break;
        }
        case TmSocialInfos.CITY: {
          item.text = (user?.city ? user?.city + ', ' : '') + (user?.country || '');
          break;
        }
        case TmSocialInfos.PHONE: {
          item.text = user?.phoneCode ? `${user.phoneCode} ${user.phoneNumber}` : '';
          break;
        }
        case TmSocialInfos.BIRTHDAY: {
          item.text = user?.birthDate ? sfFormatDate(user.birthDate, 'yyyy-MM-dd') : '';
          break;
        }
      }
    });

    return this.httpClient.post<Template>('api/v1/builder/create', {...template});
  }

  public addTemplate_for_upload(template: Template) {
    return this.httpClient.post<Template>('api/v1/builder/create', {...template});
  }

  public duplicateTemplate(template: Template) {
    return this.httpClient.post<Template>('api/v1/dashboard/duplicate', {documentId: template.documentId});
  }

  public deleteTemplate(template: Template) {
    return this.httpClient.delete<Template>(`api/v1/dashboard/delete-document/${template.documentId}`);
  }

  public updateTemplateTitle(template: Template) {
    return this.httpClient.post<{ success: true }>('api/v1/dashboard/set-title', {
      title: template.title,
      documentId: template.documentId
    });
  }

  public getTemplate(documentId: string) {
    return this.httpClient.get<Template>(`api/v1/builder/get-document/${documentId}`);
  }

  public saveChanges(template: Template) {
    this.appStateService.templatesLoaderState = true;
    return this.httpClient.post<{ success: true }>('api/v1/builder/sync-document', {
      settings: template.settings,
      documentId: template.documentId,
      templateId: template.templateId,
    })
      .pipe(
        finalize(() => this.appStateService.templatesLoaderState = false)
      );
  }

  public updateTemplateImage(documentId: string, blob: Blob) {
    return this.httpClient.post<{ src: string }>(`api/v1/documents/${documentId}/upload`, createFormData(blob));
  }

  public getTemplatesToSelect() {
    return this.httpClient.get<Template[]>('assets/project-configs/templates-to-select/cv-templates.json');
  }

  public getTemplateUrl(body: { documentId: string | undefined; userId: string | undefined; }) {
    return this.httpClient.post<{ src: string }>(
      'https://seal-app-eyzjj.ondigitalocean.app/pdfs/link',
      body,
      {
        context: setContext({
          skipPrefix: true,
        }),
      }
    );
  }

  public downloadTemplate(body: { documentId: string | undefined; userId: string | undefined; documentName: string; }) {
    return this.httpClient.post<{ src: string }>(
      'https://seal-app-eyzjj.ondigitalocean.app/pdfs/file',
      // 'http://localhost:3000/pdfs/file',
      body,
      {
        context: setContext({
          skipPrefix: true,
        }),
      }
    );
  }

}
