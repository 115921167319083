import {DefaultUrlSerializer, UrlTree} from '@angular/router';

export class TrailingSlashUrlSerializer extends DefaultUrlSerializer {
  override serialize(tree: UrlTree): string {
    return this._withTrailingSlash2(super.serialize(tree));
  }

  private _withTrailingSlash2(url: string): string {
    const splitOn = url.indexOf('?') > -1 ? '?' : '#';
    const pathArr = url.split(splitOn);

    if (!pathArr[0].endsWith('/')) {
      const fileName: string = url.substring(url.lastIndexOf('/') + 1);
      if (fileName.indexOf('.') === -1) {
        pathArr[0] += '/';
      }
    }
    return pathArr.join(splitOn);
  }

  private _withTrailingSlash(url: string): string {

    const clientUrls = ['account/user', 'ccount/security', 'account/billing-history', 'account/choose-plan', 'dashboard', 'documents'];


    console.log('url', url);

    if (clientUrls.some((route: string) => url.includes(route))) {
      return url; // Prevent redirect loop for CSR pages
    }

    const splitOn = url.indexOf('?') > -1 ? '?' : '#';
    const pathArr = url.split(splitOn);

    if (!pathArr[0].endsWith('/')) {
      const fileName: string = url.substring(url.lastIndexOf('/') + 1);
      if (fileName.indexOf('.') === -1) {
        pathArr[0] += '/';
      }
    }
    return pathArr.join(splitOn);
  }

}




