export const loadScript = (src: string, type = 'text/javascript') => {
  return new Promise((resolve, reject) => {
    // Check if script already exists
    if (document.querySelector(`script[src="${src}"]`)) {
      return resolve(`Script ${src} is already loaded.`);
    }

    const script = document.createElement('script');

    script.src = src;
    script.async = true;
    script.type = type;

    script.onload = () => resolve(`Script ${src} loaded successfully.`);
    script.onerror = () => reject(`Failed to load script: ${src}`);

    document.head.appendChild(script);
  });
}

export const loadCSS = (href: string) => {
  return new Promise((resolve, reject) => {
    // Check if the CSS file is already loaded
    if (document.querySelector(`link[href="${href}"]`)) {
      return resolve(`CSS file ${href} is already loaded.`);
    }

    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = href;
    link.onload = () => resolve(`CSS file ${href} loaded successfully.`);
    link.onerror = () => reject(`Failed to load CSS file: ${href}`);

    document.head.appendChild(link);
  });
}

